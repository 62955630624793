import {useState, useEffect} from "react";
import "./App.css";
import Card from "./Components/Card/Card";
import Cart from "./Components/Cart/Cart";

const tg = window.Telegram.WebApp;
function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id');
    const [cartItems, setCartItems] = useState([]);
    const [foods, setFoods] = useState([]);

     useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.fightclub.top/get_items?user_id=${userId}`);
        const data = await response.json();
        setFoods(data);
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchData();
  }, [userId]);

  const onAdd = (food) => {
    const exist = cartItems.find((x) => x.id === food.id);
    const maxQuantity = food.quantity;
    if (exist) {
      if (exist.quantity < maxQuantity) {
        setCartItems(
          cartItems.map((x) =>
            x.id === food.id ? { ...exist, quantity: exist.quantity + 1 } : x
          )
        );
      }
    } else {
      setCartItems([...cartItems, { ...food, quantity: 1 }]);
    }
  };

  const onRemove = (food) => {
    const exist = cartItems.find((x) => x.id === food.id);
    if (exist.quantity === 1) {
      setCartItems(cartItems.filter((x) => x.id !== food.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === food.id ? { ...exist, quantity: exist.quantity - 1 } : x
        )
      );
    }
  };

  const onCheckout = () => {
    const items = cartItems.map(item => ({
      title: item.title,
      quantity: item.quantity,
      price: item.price
    }));
    const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const order = {
      items: items,
      total: total
    };
    tg.sendData(JSON.stringify(order));
    tg.close();
  };

  return (
    <>
      <h1 className="heading" style={{ marginLeft: "-115px", marginTop: "7px", fontSize: "2.5rem", textShadow: "-5px 5px 4px rgba(0,0,0,0.5)"}}>☰ Fight Club</h1>
      <Cart cartItems={cartItems} onCheckout={onCheckout} />
      <div className="cards__container">
        {foods.map((food) => (
          <Card food={food} key={food.id} onAdd={onAdd} onRemove={onRemove} />
        ))}
      </div>
    </>
  );
}

export default App;